<template>
  <div>
    <card class="mr-2 shadow" body-classes="standard-card-body">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('deal.deal_data') }}</h4>
          <base-button link icon size="sm" class="ml-auto text-capitalize" @click="openModal" v-if="!readOnly">
            <octo-icon icon="edit"/>
          </base-button>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <list-group-item-component :value="deal.date_in | date" :label="$t('fields.date_start')"/>
        <list-group-item-component :value="deal.date_out | date" :label="$t('deal.date_end_contract')"/>
        <list-group-item-component :label="$t('fields.deal_status')">
          <template slot="value" v-if="deal.status">
            <div class="d-flex flex-column align-items-end">
              <badge :style="{backgroundColor: schedulerService.getDealStatusColor(deal.status)}"
                     class="text-uppercase" v-if="deal.status">
                {{ $t('deal.status.' + deal.status) }}
              </badge>
              <div v-if="deal.sub_status" class="small text-uppercase">
                {{ $t('deal.status.' + deal.sub_status) }}
              </div>
            </div>
          </template>
        </list-group-item-component>
        <list-group-item-component :value="deal.recall_count || 0" :label="$t('deal.recall_count')"/>
        <list-group-item-component :label="$t('fields.operator')">
          <template slot="value" v-if="deal.lead && deal.lead.user_id">
            <label-theme-component class="text-uppercase">
              {{ users[deal.lead.user_id] | optional('lastname') }}
              {{ users[deal.lead.user_id] | optional('firstname') }}
            </label-theme-component>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.sale')">
          <template slot="value" v-if="deal.user_id">
            <label-theme-component class="text-uppercase">
              {{ users[deal.user_id] | optional('lastname') }}
              {{ users[deal.user_id] | optional('firstname') }}
            </label-theme-component>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.weekly_frequency')" :value="deal.weekly_frequency"/>

        <list-group-item-component :label="$t('fields.classroom_info')" :value="deal.classroom_info"/>

        <list-group-item-component :label="$t('fields.individual_course')">
          <template slot="value">
            <label-theme-component class="text-uppercase">
              {{ deal.individual_course ? $t('common.yes') : $t('common.no') }}
            </label-theme-component>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.sponsor')">
          <template slot="value" v-if="deal.sponsor_id">
            <label-theme-component>
              {{ users[deal.sponsor_id] | optional('lastname') }}
              {{ users[deal.sponsor_id] | optional('firstname') }}
            </label-theme-component>
          </template>
        </list-group-item-component>
      </ul>
    </card>
    <card class="mr-2 shadow" body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('deal.deal_notes') }}</h4>
      </div>
      <div class="px-2" v-html="deal.text"/>
    </card>
    <modal bodyClasses="p-1" :show.sync="showModal" centered>
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('callcenter.deal_data') }}
      </h5>
      <deal-card-form
        :deal="deal"
        :available-transitions="availableTransitions"
        :key="dealFormKey"
        ref="dealForm"
      />
      <template slot="footer">
        <base-button class="text-uppercase" link @click="saveDeal">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>

</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Badge from "@/components/Badge";
import {schedulerService} from "@/util/scheduler-service";
import Modal from "@/components/Modal";
import DealCardForm from "./DealCardForm";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "DealCard",
  components: {DealCardForm, Modal, Badge, LabelThemeComponent, ListGroupItemComponent, OctoIcon},
  data() {
    return {
      schedulerService: schedulerService,
      showModal: false,
      dealFormKey: 0,
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },
    availableTransitions: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      users: 'common/users',
    }),

  },
  methods: {
    openModal() {
      this.showModal = true;
      this.dealFormKey++;
    },

    saveDeal() {
      this.$fullLoading.show();

      const dealFormData = this.$refs.dealForm.getDealData();

      this.$api.put(endpoints.DEAL_UPDATE.replace('{id}', this.deal.id), dealFormData)
        .then((resp) => {
          this.$emit('onUpdateDealData', resp?.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },
  }
}
</script>

<style scoped>

</style>
