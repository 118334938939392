<template>
  <card class="shadow" body-classes="standard-card-body" footer-classes="text-right text-uppercase text-info pt-0">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('page.courses') }}</h4>
    </div>
    <div class="row align-items-end" v-if="!readOnly">
      <div class="col flex-grow-1">
        <base-input>
          <el-select
            class="select-default text-uppercase"
            value-key="code"
            :placeholder="$t('common.type_to_search')"
            v-model="selectedCourse"
          >
            <el-option
              v-for="option in courseOptions"
              class="select-default text-uppercase"
              :value="option"
              :label="option.title"
              :key="option.code"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-auto form-group">
        <base-button @click="addCourse" icon link class="ml-auto text-capitalize mb-0">
          <octo-icon icon="add"/>
        </base-button>
      </div>
    </div>
    <ul class="list-group list-group-flush">
      <list-group-item-component v-for="(detail, index) in dealDetailsLocal" v-bind:key="`detail-${index}`">
        <template v-slot:label="{valueClass}">
          <div class="standard-label text-truncate">
            <div>{{ detail.detailable.title }}</div>
            <div>{{ $t('fields.code') }} : <span :class="valueClass">{{ detail.detailable.code }}</span></div>
          </div>
        </template>
        <template v-slot:value="{valueClass}">
          <div class="ml-auto" :class="valueClass">
            {{ detail.detailable.price | euro }}
          </div>
          <el-tooltip v-if="!readOnly" content="Delete" :open-delay="300" placement="top">
            <base-button class="ml-2" type="danger" size="sm" icon link @click="deleteCourse(index)">
              <octo-icon icon="wrong"/>
            </base-button>
          </el-tooltip>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('deal.suggested_total')" :value="total | euro"/>
    </ul>
  </card>
</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import swal from "sweetalert2";
import BaseAlert from "@/components/BaseAlert";
import {Select, Option, MessageBox} from "element-ui";
import DealDetail from "@/models/dealDetails";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "DealCoursePicker",
  components: {ListGroupItemComponent, BaseAlert, OctoIcon, [Select.name]: Select, [Option.name]: Option},
  props: {
    dealDetails: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCourse: null,
      dealDetailsLocal: [],
    }
  },
  mounted() {
    this.dealDetailsLocal = this.$_.cloneDeep(this.dealDetails);
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses'
    }),
    total() {
      let total = 0;

      this.$_.each(this.dealDetailsLocal, detail => {
        total += Number(detail.detailable.price);
      })

      return total;
    },

    courseOptions() {
      const courseIds = this.$_.map(this.dealDetailsLocal, detail => {
        return detail?.detailable?.id;
      });

      return this.$_.filter(this.courses, course => {
        return !this.$_.find(courseIds, id => {
          return course.id === id;
        });
      });
    }
  },
  methods: {
    getDealDetails: function () {
      return this.dealDetailsLocal;
    },

    deleteCourse: function (index) {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_course'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.dealDetailsLocal.splice(index, 1);
        })
        .catch(() => {
        });
    },

    addCourse() {
      if (!this.$_.isEmpty(this.selectedCourse)) {
        let dealDetail = this.$_.cloneDeep(DealDetail);
        dealDetail.amount = this.selectedCourse.price;
        dealDetail.detailable = this.selectedCourse;
        dealDetail.detailable_id = this.selectedCourse.id;
        dealDetail.detailable_type = 'Course';

        this.dealDetailsLocal.push(dealDetail);
        this.selectedCourse = {};
      }
    }
  }
}
</script>

<style scoped>

</style>
