const DealDebtCollectionStatus = {
  send_to_lawyer: {
    value: 'send_to_lawyer',
    color: 'warning',
  },
  dispatched_to_lawyer: {
    value: 'dispatched_to_lawyer',
    color: 'info'
  }
}

export default DealDebtCollectionStatus
