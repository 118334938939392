<template>
  <div class="row" v-observe-visibility="visibilityChanged">
    <div class="col-12">
      <octo-loader v-if="isLoading"/>
    </div>
    <div class="col-12 mb-3" v-for="(student, index) in students" v-bind:key="`deal-student-${index}`">
      <card body-classes="standard-card-body" class="shadow mb-0">
        <div slot="header">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <h4 class="card-title text-capitalize">
                <label-theme-component>{{ courses[student.course_id] | optional('code') }}:</label-theme-component>
                {{ courses[student.course_id] | optional('title') }}
              </h4>
              <badge v-if="student.mainClassroomStatus" :type="student.mainClassroomStatus | classroomStatusColor" class="text-uppercase ml-3">
                {{ $t('didactics.classroom_status.' + student.mainClassroomStatus ) }}
              </badge>
            </div>

            <base-button
              link
              class="text-uppercase my-0"
              @click="toggleAccountingStatus(student)"
              v-if="courses[student.course_id].type === courseTypes.certificate.value
                    || courses[student.course_id].type === courseTypes.workshop.value"
            >
              {{ student.accounting_approval ? $t('common.reject') : $t('common.approve') }}
            </base-button>
          </div>
        </div>
        <student-lessons
          :classroom-lessons="student.mainClassroomLessons"
          :student-lessons="student.lessons" />
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import {Table, TableColumn} from "element-ui";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {endpoints} from "@/routes/endpoints";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import Badge from "@/components/Badge";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import {ObserveVisibility} from "vue-observe-visibility";
import StudentLessons from "@/pages/Students/components/StudentLessons";

export default {
  name: "DealStudents",
  components: {
    StudentLessons,
    Badge,
    LabelThemeComponent,
    OctoIcon,
    OctoLoader,
    OctoHeaderButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    }
  },
  data() {
    return {
      students: [],
      courseTypes: classroomTypes,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/allCourses',
      users: 'common/users',
    }),
  },
  directives: {
    'observe-visibility': ObserveVisibility
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible && this.students.length === 0) {
        this.isLoading = true;

        this.$api.get(endpoints.DEAL_STUDENTS.replace('{id}', this.deal.id))
          .then((resp) => {

            this.students = this.$_.map(resp?.data?.data || [], (student) => {
                return {
                  id: student.id,
                  course_id: student.course_id,
                  accounting_approval: student.accounting_approval,
                  lessons: student.lessons,
                  mainClassroomStatus: this.$_.find(student.classrooms || {}, {student_classroom: 'main'})?.status || null,
                  mainClassroomLessons: this.$_.find(student.classrooms || {}, {student_classroom: 'main'})?.lessons || []
                }
              });
          })
          .catch(() => {
            this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
          })
          .finally(() => {
            this.isLoading = false;
          })
      }
    },

    toggleAccountingStatus(student) {
      this.$fullLoading.show();

      this.$api.put(endpoints.STUDENT_TOGGLE_ACCOUNTING_APPROVAL.replace('{id}', student.id))
        .then((resp) => {
          this.$set(
            this.students[this.$_.findIndex(this.students, item => item.id === student.id)],
            'accounting_approval',
            resp?.data?.data?.accounting_approval
          );
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    },

    getAllLessons(student) {
      const lessons = [];

      this.$_.each(student.classrooms, classroom => {
        this.$_.each(classroom.lessons, lesson => lessons.push(lesson))
      });

      return this.$_.sortBy(lessons, 'date');
    }
  }
}
</script>

<style scoped>

</style>
