<template>
  <ValidationObserver ref="paymentForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.amount')">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="amount"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input class="mb-0"
                        type="number"
                        v-model="paymentLocal.amount"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>
      <list-group-item-component :label="$t('fields.deadline')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider name="deadline" class="flex-grow-1" rules="required" v-slot="{ passed, failed, errors }">
            <base-input required
                        class="mb-0"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-date-picker
                type="date"
                v-model="deadlineDay"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('common.select_day')"
                :picker-options="pickerOptions"
              />
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.payment_date')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-date-picker
              type="date"
              v-model="paymentDay"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              :placeholder="$t('common.select_day')"
              :picker-options="pickerOptions"
            />
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.expiration_date')" :label-width="labelWidth">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-date-picker
              type="date"
              v-model="agreedDeadline"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              :placeholder="$t('common.select_day')"
              :picker-options="pickerOptions"
            />
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.payment_type')" :label-width="labelWidth">
        <template slot="value">
            <base-input class="flex-grow-1 mb-0">
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="paymentLocal.payment_type"
              >
                <el-option
                  v-for="payment in paymentTypeConst"
                  class="select-default text-uppercase"
                  :value="payment.value"
                  :label="$t('deal.payment_types.' + payment.value)"
                  :key="payment.value"
                />
              </el-select>
            </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.status')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            name="status"
            class="flex-grow-1"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="flex-grow-1 mb-0"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="paymentLocal.status"
              >
                <el-option
                  v-for="status in paymentStatusConst"
                  class="select-default text-uppercase"
                  :value="status.value"
                  :label="$t('deal.payment_statuses.' + status.value)"
                  :key="status.value"
                />
              </el-select>
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.reason')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            name="reason"
            class="flex-grow-1"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              class="flex-grow-1 mb-0"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-select
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="paymentLocal.reason"
              >
                <el-option
                  v-for="reason in paymentReasonConst"
                  class="select-default text-uppercase"
                  :value="reason.value"
                  :label="$t('deal.payment_reasons.' + reason.value)"
                  :key="reason.value"
                />
              </el-select>
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component>
        <template slot="value">
          <base-text-area
            class="flex-grow-1"
            :label="$t('fields.description')"
            :placeholder="$t('fields.description')"
            v-model="paymentLocal.description"
          />
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import {Select, Option, DatePicker} from 'element-ui';
import Payment from "@/models/payment";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import PaymentTypeConst from "../resources/paymentTypeConst";
import PaymentReasonConst from "../resources/paymentReasonConst";
import PaymentStatusConst from "../resources/paymentStatusConst";

export default {
  name: "DealPaymentTabForm",
  data() {
    return {
      paymentLocal: Payment,
      deadlineDay: '',
      paymentDay: '',
      agreedDeadline: '',
      labelWidth: 150,
      paymentTypeConst: PaymentTypeConst,
      paymentReasonConst: PaymentReasonConst,
      paymentStatusConst: PaymentStatusConst,
    }
  },
  components: {
    ListGroupItemComponent,
    BaseTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [DatePicker.name]: DatePicker
  },
  props: {
    payment: {
      type: Object,
      default: () => this.$_.cloneDeep(Payment)
    }
  },
  mounted() {
    this.paymentLocal = this.$_.cloneDeep(this.payment);
    this.deadlineDay = this.getDayFromDateTimeString(this.paymentLocal.deadline);
    this.paymentDay = this.getDayFromDateTimeString(this.paymentLocal.payment_date);
    this.agreedDeadline = this.getDayFromDateTimeString(this.paymentLocal.agreed_deadline);

  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),

    pickerOptions() {
      return {firstDayOfWeek: 1};
    },
  },
  methods: {
    getDayFromDateTimeString(dateTimeString) {
      return dateTimeString ? this.$moment(dateTimeString).format('YYYY-MM-DD') : '';
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.paymentForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              this.paymentLocal.deadline = this.deadlineDay
                ? this.$moment(this.deadlineDay).format('YYYY-MM-DD HH:mm:ss')
                : '';

              this.paymentLocal.payment_date = this.paymentDay
                ? this.$moment(this.paymentDay).format('YYYY-MM-DD HH:mm:ss')
                : '';

              this.paymentLocal.agreed_deadline = this.agreedDeadline
                ? this.$moment(this.agreedDeadline).format('YYYY-MM-DD HH:mm:ss')
                : '';

              resolve(this.paymentLocal);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
}
</script>

<style scoped>

</style>
