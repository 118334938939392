<template>
  <card class="shadow" body-classes="standard-card-body pt-0">
    <div slot="header">
      <div class="d-flex align-items-center">
        <h4 class="card-title text-capitalize">{{ $t('deal.contract') }}</h4>
        <base-button
          v-if="!readOnly"
          link
          icon
          size="sm"
          class="ml-auto text-capitalize my-0"
          @click="openModal"
        >
          <octo-icon icon="edit"/>
        </base-button>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <ul class="list-group list-group-flush">
            <list-group-item-component
              :label="$t('fields.contract_amount')"
              :value="dealExtraInfo.contract_amount | euro"
            />

            <list-group-item-component :label="$t('fields.payment_type')">
              <template slot="value">
                <label-theme-component v-if="dealExtraInfo.payment_type">
                  {{ $t('deal.payment_types.' + dealExtraInfo.payment_type) }}
                </label-theme-component>
              </template>
            </list-group-item-component>

            <list-group-item-component :label="$t('fields.installment_plan')">
              <template slot="value">
                <label-theme-component v-if="dealExtraInfo.installment_plan">
                  {{ dealExtraInfo.installment_plan }}
                </label-theme-component>
              </template>
            </list-group-item-component>

            <list-group-item-component
              :label="$t('fields.enrollment_amount')"
              :value="dealExtraInfo.enrollment_amount | euro"
            />
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.enrollment_payment_type')">
              <template slot="value">
                <label-theme-component v-if="dealExtraInfo.enrollment_payment_type">
                  {{ $t('deal.payment_types.' + dealExtraInfo.enrollment_payment_type) }}
                </label-theme-component>
              </template>
            </list-group-item-component>

            <list-group-item-component
              :label="$t('fields.withheld_fee')"
              :value="dealExtraInfo.withheld_fee ? $t('common.yes') : $t('common.no')"
            />
            <list-group-item-component :label="$t('fields.km_traveled')"
                                       :value="dealExtraInfo.km_traveled + ' Km'"/>

            <list-group-item-component
              :label="$t('fields.consulting_in_presence')"
              :value="dealExtraInfo.consulting_in_presence ? $t('common.yes') : $t('common.no')"
            />
          </ul>
        </div>
      </div>
    </div>

    <modal bodyClasses="p-2" :show.sync="showModal" centered>
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('deal.contract') }}
      </h5>
      <deal-contract-data-card-form
        :deal-extra-info="dealExtraInfo"
        :key="`deal-extra-info-${formKey}`"
        ref="dealContractDataCardForm"
      />
      <template slot="footer">
        <base-button class="text-uppercase" link @click="updateDealContractData">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import {Select, Option, Checkbox, Switch} from 'element-ui';
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import DealContractDataCardForm from "./DealContractDataCardForm";
import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import DealCoursePickerPlain from "./DealCoursePickerPlain";
import DealExtraInfo from "@/models/dealExtraInfo";

export default {
  name: "DealContractDataCard",
  components: {
    DealCoursePickerPlain,
    DealContractDataCardForm,
    Modal,
    OctoIcon,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
  },
  data() {
    return {
      showModal: false,
      formKey: 0
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openModal() {
      this.formKey++;
      this.showModal = true;
    },

    updateDealContractData: async function () {
      try {
        const dealExtraInfo = await this.$refs.dealContractDataCardForm.getDealExtraInfo();

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.DEAL_UPDATE_CONTRACT_DATA.replace('{id}', this.deal.id),
          {dealExtraInfo: dealExtraInfo}
        );

        this.$emit('onUpdateDealOnly', resp?.data?.data);

        this.showModal = false;

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    }
  },
  computed: {
    dealExtraInfo() {
      return this.deal?.dealExtraInfo || this.$_.cloneDeep(DealExtraInfo);
    }
  }
}
</script>

<style scoped>

</style>
