<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12 col-md-6">
        <card class="shadow" body-classes="standard-card-body" header-classes="p-2 pb-0">
          <div slot="header">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title text-capitalize">{{ $t('deal.holder') }}</h4>
              <div class="ml-auto">
                <base-button v-if="editable" link icon size="sm" class="my-0" @click="openModalHolder">
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button v-if="editable && dealHolderLocal.id" link icon size="sm" type="danger" class="my-0" @click="deleteDealHolder">
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.businessname')" :value="dealHolderLocal.businessname"/>
            <list-group-item-component :label="$t('fields.unique_code')" :value="dealHolderLocal.unique_code"/>
            <list-group-item-component :label="$t('fields.codfis')" :value="dealHolderLocal.codfis"/>
            <list-group-item-component :label="$t('fields.piva')" :value="dealHolderLocal.piva"/>
            <list-group-item-component :label="$t('fields.phone')" :value="dealHolderLocal.phone"/>
            <list-group-item-component :label="$t('fields.email')" :value="dealHolderLocal.email"/>
          </ul>
        </card>
        <modal bodyClasses="p-1" :show.sync="showModalHolder" centered>
          <h5 slot="header" class="modal-title text-uppercase">
            {{ $t('deal.holder') }}
          </h5>
          <deal-holder-form :deal-holder="dealHolderLocal" :key="formKey" ref="dealHolderForm"/>
          <template slot="footer">
            <base-button class="text-uppercase" link @click="saveDealHolderData">
              {{ $t('common.save') }}
            </base-button>
          </template>
        </modal>
      </div>
      <div class="col-12 col-md-6">
        <card class="shadow" body-classes="standard-card-body" header-classes="p-2 pb-0">
          <div slot="header">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="card-title text-capitalize">{{ $t('deal.financial_holder') }}</h4>
              <div class="ml-auto">
                <base-button v-if="editable" link icon size="sm" class="my-0" @click="openModalFinancialHolder">
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button v-if="editable && dealFinancialHolderLocal.id" link icon size="sm" type="danger" class="my-0" @click="deleteDealFinancialHolder">
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <list-group-item-component :label="$t('fields.businessname')" :value="dealFinancialHolderLocal.businessname"/>
            <list-group-item-component :label="$t('fields.unique_code')" :value="dealFinancialHolderLocal.unique_code"/>
            <list-group-item-component :label="$t('fields.codfis')" :value="dealFinancialHolderLocal.codfis"/>
            <list-group-item-component :label="$t('fields.piva')" :value="dealFinancialHolderLocal.piva"/>
            <list-group-item-component :label="$t('fields.phone')" :value="dealFinancialHolderLocal.phone"/>
            <list-group-item-component :label="$t('fields.email')" :value="dealFinancialHolderLocal.email"/>
          </ul>
        </card>
        <modal bodyClasses="p-1" :show.sync="showModalFinancialHolder" centered>
          <h5 slot="header" class="modal-title text-uppercase">
            {{ $t('deal.financial_holder') }}
          </h5>
          <deal-holder-financial-form :deal-financial-holder="dealFinancialHolderLocal" :key="formKey" ref="dealFinancialHolderForm"/>
          <template slot="footer">
            <base-button class="text-uppercase" link @click="saveDealFinancialHolderData">
              {{ $t('common.save') }}
            </base-button>
          </template>
        </modal>
      </div>
    </div>


  </div>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import Modal from "@/components/Modal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import DealHolderForm from "@/pages/Deals/components/DealHolderForm";
import DealHolder from "@/models/dealHolder";
import BaseAlert from "@/components/BaseAlert";
import Deal from "@/models/deal";
import {MessageBox} from "element-ui";
import DealFinancialHolder from "@/models/dealFinancialHolder";
import DealHolderFinancialForm from "@/pages/Deals/components/DealFInancialHolderForm.vue";
import DealEmailLabel from "@/pages/Deals/resources/dealEmailLabel";

export default {
  name: "DealHolderData",
  components: {
    DealHolderFinancialForm,
    BaseAlert,
    DealHolderForm,
    ListGroupItemComponent,
    OctoIcon,
    Modal,
    BaseTable
  },
  data() {
    return {
      showModalHolder: false,
      showModalFinancialHolder: false,
      formKey: 1,
      dealHolderLocal: this.$_.cloneDeep(DealHolder),
      dealFinancialHolderLocal: this.$_.cloneDeep(DealFinancialHolder),
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.dealHolderLocal = this.$_.cloneDeep(this.deal?.dealHolder || DealHolder);
    this.dealHolderLocal.email = this.$_.find(this.deal?.registry?.emails, ['label', DealEmailLabel.holder.value])?.email
    this.dealHolderLocal.phone = this.$_.find(this.deal?.registry?.phones, ['label', DealEmailLabel.holder.value])?.phone
    this.dealFinancialHolderLocal = this.$_.cloneDeep(this.deal?.dealFinancialHolder || DealFinancialHolder);
    this.dealFinancialHolderLocal.email = this.$_.find(this.deal?.registry?.emails, ['label', DealEmailLabel.financial_holder.value])?.email
    this.dealFinancialHolderLocal.phone = this.$_.find(this.deal?.registry?.phones, ['label', DealEmailLabel.financial_holder.value])?.phone
  },
  methods: {
    openModalHolder() {
      this.showModalHolder = true;
      this.formKey++;
    },

    openModalFinancialHolder() {
      this.showModalFinancialHolder = true;
      this.formKey++;
    },

    saveDealHolderData: async function () {
      try {
        const dealHolder = await this.$refs.dealHolderForm.validate();
        this.$fullLoading.show();

        const resp = dealHolder?.id
            ? await this.$api.put(endpoints.DEAL_HOLDER_UPDATE.replace('{id}', dealHolder.id), dealHolder)
            : await this.$api.post(endpoints.DEAL_HOLDER_STORE_BY_DEAL.replace('{dealId}', this.deal.id), dealHolder);

        this.dealHolderLocal = resp?.data?.data;
        this.dealHolderLocal.email = dealHolder.email;
        this.dealHolderLocal.phone = dealHolder.phone;
        this.formKey++;
        this.showModalHolder = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    saveDealFinancialHolderData: async function () {
      try {
        const dealFinancialHolder = await this.$refs.dealFinancialHolderForm.validate();
        this.$fullLoading.show();

        const resp = dealFinancialHolder?.id
            ? await this.$api.put(endpoints.DEAL_FINANCIAL_HOLDER_UPDATE.replace('{id}', dealFinancialHolder.id), dealFinancialHolder)
            : await this.$api.post(endpoints.DEAL_FINANCIAL_HOLDER_STORE_BY_DEAL.replace('{dealId}', this.deal.id), dealFinancialHolder);

        this.dealFinancialHolderLocal = resp?.data?.data;
        this.dealFinancialHolderLocal.email = dealFinancialHolder.email;
        this.dealFinancialHolderLocal.phone = dealFinancialHolder.phone;
        this.formKey++;
        this.showModalFinancialHolder = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    deleteDealHolder: function () {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_holder'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.$fullLoading.show();
          this.$api.delete(endpoints.DEAL_HOLDER_DELETE.replace('{id}', this.dealHolderLocal.id))
            .then(() => {
              this.dealHolderLocal = this.$_.cloneDeep(DealHolder);
              this.showModalHolder = false;
              this.formKey++;
            })
            .catch(() => {
              this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
            })
            .finally(() => {
              this.$fullLoading.hide();
            });
        })
        .catch(() => {
        });
    },

    deleteDealFinancialHolder: function () {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_holder'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.$fullLoading.show();
          this.$api.delete(endpoints.DEAL_FINANCIAL_HOLDER_DELETE.replace('{id}', this.dealFinancialHolderLocal.id))
            .then(() => {
              this.dealFinancialHolderLocal = this.$_.cloneDeep(DealFinancialHolder);
              this.showModalFinancialHolder = false;
              this.formKey++;
            })
            .catch(() => {
              this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
            })
            .finally(() => {
              this.$fullLoading.hide();
            });
        })
        .catch(() => {
        });
    },
  }
}
</script>

<style scoped>

</style>
