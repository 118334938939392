<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label-width="labelWidth" :label="$t('deal.date_end_contract')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-date-picker
            type="date"
            v-model="dealLocal.date_out"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            :placeholder="$t('common.select_day')"
            :picker-options="pickerOptions"
          />
        </base-input>
        <label-theme-component v-else>
          {{ dealLocal.date_out | date }}
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.competence_date')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-date-picker
            type="date"
            v-model="dealLocal.competence_date"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            :placeholder="$t('common.select_day')"
            :picker-options="pickerOptions"
          />
        </base-input>
        <label-theme-component v-else>
          {{ dealLocal.competence_date | date }}
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.accounting_status')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealLocal.accounting_status"
          >
            <el-option
                v-for="(option, index) in dealAccountingStatus"
                v-bind:key="`accounting_status-${index}`"
                class="select-default text-uppercase"
                :value="option.value"
                :label="$t('deal.accounting_statuses.' + option.value)"
            >
            </el-option>
          </el-select>
        </base-input>
        <label-theme-component v-else>
          <template v-if="dealLocal.accounting_status">
          {{ $t('deal.accounting_statuses.' + dealLocal.accounting_status) }}
          </template>
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.confirmed_date')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-date-picker
            type="date"
            v-model="confirmedDate"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            :placeholder="$t('common.select_day')"
            :picker-options="pickerOptions"
          />
        </base-input>
        <label-theme-component v-else>
          {{ confirmedDate | date }}
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.financing_status')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealLocal.dealExtraInfo.financing_status"
              clearable
          >
            <el-option
                v-for="(option, index) in dealFinancialStatus"
                v-bind:key="`financing_status-${index}`"
                class="select-default text-uppercase"
                :value="option.value"
                :label="$t('deal.financing_statuses.' + option.value)"
            >
            </el-option>
          </el-select>
        </base-input>
        <label-theme-component v-else>
          <template v-if="dealLocal.dealExtraInfo && dealLocal.dealExtraInfo.financing_status">
          {{ $t('deal.financing_statuses.' + dealLocal.dealExtraInfo.financing_status) }}
          </template>
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.debt_collection_status')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealLocal.dealExtraInfo.debt_collection_status"
              clearable
          >
            <el-option
                v-for="(option, index) in dealDebtCollectionStatus"
                v-bind:key="`debt_collection_status-${index}`"
                class="select-default text-uppercase"
                :value="option.value"
                :label="$t('deal.debt_collection_statuses.' + option.value)"
            >
            </el-option>
          </el-select>
        </base-input>
        <label-theme-component v-else>
          <template v-if="dealLocal.dealExtraInfo && dealLocal.dealExtraInfo.debt_collection_status">
          {{ $t('deal.debt_collection_statuses.' + dealLocal.dealExtraInfo.debt_collection_status) }}
          </template>
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.date_lawyer')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-date-picker
            type="date"
            v-model="dealLocal.dealExtraInfo.lawyer_date"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            :placeholder="$t('common.select_day')"
            :picker-options="pickerOptions"
          />
        </base-input>
        <label-theme-component v-else>
          {{ dealLocal.dealExtraInfo.lawyer_date | date }}
        </label-theme-component>
      </template>
    </list-group-item-component>


  </ul>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Deal from "@/models/deal";
import {DatePicker, Select, Option} from "element-ui";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {permissionService} from "@/util/permission-service";
import DealFinancialStatus from "@/pages/Deals/resources/dealFinancialStatus";
import DealDebtCollectionStatus from "@/pages/Deals/resources/dealDebtCollectionStatus";

export default {
  name: "DealAccountingCardForm",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      labelWidth: 180,
      dealLocal: this.$_.cloneDeep(Deal),
      dealAccountingStatus: DealAccountingStatus,
      dealFinancialStatus: DealFinancialStatus,
      dealDebtCollectionStatus: DealDebtCollectionStatus,
      confirmedDate: '',
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    },
  },
  mounted() {
    this.dealLocal = this.$_.cloneDeep(this.deal);
    this.confirmedDate = this.deal?.dealExtraInfo?.confirmed_date;
  },
  methods: {
    getDealAccountingData() {
      return {
        accounting_status: this.dealLocal.accounting_status,
        financing_status: this.dealLocal.dealExtraInfo.financing_status,
        debt_collection_status: this.dealLocal.dealExtraInfo.debt_collection_status,
        competence_date: this.dealLocal.competence_date,
        confirmed_date: this.confirmedDate,
        date_out: this.dealLocal.date_out,
        lawyer_date:  this.dealLocal.dealExtraInfo.lawyer_date
      };
    }
  },
  computed: {
    isUserManagerOrAccountant() {
      return permissionService.isAllowed([
        permissionService.CALL_CENTER_MANAGER_ROLE,
        permissionService.SALES_MANAGER_ROLE,
        permissionService.ACCOUNTANT_ROLE,
      ]);
    },

    pickerOptions() {
      return {firstDayOfWeek: 1};
    }
  }
}
</script>

<style scoped lang="scss">

</style>
