const DealDocumentLabels = [
  'contract',
  'id_card_front',
  'id_card_back',
  'id_card_front_back',
  'healthcare_card_front',
  'healthcare_card_back',
  'healthcare_card_front_back',
  'residence_permit_front',
  'residence_permit_back',
  'residence_permit_front_back',
  'last_paycheck',
  'unique_model',
  'model_730',
  'pension_coupon',
  'inps_report',
  'signed_registration_form',
  'bill_of_transfer_registration_fee',
  'other'
]

export default DealDocumentLabels
