const DealFinancialHolder = {
  id: null,
  codfis: '',
  piva: '',
  businessname: '',
  unique_code: '',
  email: '',
  phone: '',
};

export default DealFinancialHolder
