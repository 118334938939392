<template>
  <ValidationObserver ref="dealHolderForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.businessname')">
        <template slot="value">
          <ValidationProvider
            name="businessname"
            rules="required"
            v-slot="{ passed, failed, errors }"
            class="flex-grow-1"
          >
            <base-input
              v-model="dealHolderLocal.businessname"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.unique_code')">
        <template slot="value">
          <ValidationProvider
            name="unique_code"
            rules="required"
            v-slot="{ passed, failed, errors }"
            class="flex-grow-1"
          >
            <base-input
              v-model="dealHolderLocal.unique_code"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.codfis')">
        <template slot="value">
          <base-input
            v-model="dealHolderLocal.codfis"
            type="text"
            maxlength="16"
            class="mb-0 flex-grow-1"
          />
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.piva')">
        <template slot="value">
          <base-input v-model="dealHolderLocal.piva" type="text" class="mb-0 flex-grow-1"/>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.email')">
        <template slot="value">
          <base-input v-model="dealHolderLocal.email" type="text" class="mb-0 flex-grow-1"/>
        </template>
      </list-group-item-component>

    </ul>
  </ValidationObserver>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import DealHolder from "@/models/dealHolder";

export default {
  name: "DealHolderForm",
  components: {ListGroupItemComponent},
  data() {
    return {
      dealHolderLocal: this.$_.cloneDeep(DealHolder),
      labelWidth: 140,
    }
  },
  props: {
    dealHolder: {
      type: Object,
      default: () => _.cloneDeep(DealHolder)
    }
  },
  mounted() {
      this.dealHolderLocal = this.$_.cloneDeep(this.dealHolder);
  },
  methods: {
    validate: function () {
      return new Promise((resolve, reject) => {
        this.$refs.dealHolderForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.dealHolderLocal);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
