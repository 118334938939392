<template>
  <card class="shadow" body-classes="standard-card-body t-0">
    <div slot="header">
      <div class="d-flex justify-content-between">
        <h4 class="card-title text-capitalize">{{ $t('common.course_management') }}</h4>
        <base-button
          link
          icon
          size="sm"
          class="ml-auto text-capitalize my-0"
          v-if="!readOnly"
          @click="showModal = true"
        >
          <octo-icon icon="edit"/>
        </base-button>
      </div>
    </div>

    <deal-course-list :title="$t('fields.courses')" :deal-details="dealDetailsWithDateableCourse"/>
    <deal-course-list :title="$t('fields.certificates')" :deal-details="dealDetailsWithDateableCertificate"/>
    <deal-course-list :title="$t('fields.workshops')" :deal-details="dealDetailsWithDateableWorkshop"/>

    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('deal.suggested_total')" :value="total | euro"/>
    </ul>

    <modal bodyClasses="p-2" :show.sync="showModal" modalClasses="modal-lg" centered>
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('fields.courses') }}
      </h5>
      <deal-course-picker-plain ref="dealCoursePicker" :deal-details="dealDetails"/>
      <template slot="footer">
        <base-button class="text-uppercase" link @click="updateDealCourses">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import DealCourseList from "./DealCourseList";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Modal from "@/components/Modal";
import DealCoursePickerPlain from "./DealCoursePickerPlain";
import {endpoints} from "@/routes/endpoints";

export default {
  name: "DealCoursesCard",
  components: {DealCoursePickerPlain, Modal, ListGroupItemComponent, DealCourseList, OctoIcon},
  data() {
    return {
      showModal: false
    }
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    }
  },
  computed: {
    total() {
      let total = 0;

      this.$_.each(this.deal.deal_details, detail => {
        total += Number(detail.detailable.price);
      })

      return total;
    },

    dealDetails() {
      return this.deal?.deal_details || [];
    },

    dealDetailsWithDateableCourse() {
      return this.$_.filter(this.deal.deal_details, detail => {
        return detail.detailable_type === 'Course' && detail?.detailable?.type === 'course';
      });
    },

    dealDetailsWithDateableCertificate() {
      return this.$_.filter(this.deal.deal_details, detail => {
        return detail.detailable_type === 'Course' && detail?.detailable?.type === 'certificate';
      });
    },

    dealDetailsWithDateableWorkshop() {
      return this.$_.filter(this.deal.deal_details, detail => {
        return detail.detailable_type === 'Course' && detail?.detailable?.type === 'workshop';
      });
    }
  },
  methods: {
    updateDealCourses: async function () {
      try {
        const dealDetails = await this.$refs.dealCoursePicker.getDealDetails();

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.DEAL_SYNC_DEAL_DETAILS.replace('{id}', this.deal.id),
          {dealDetails: dealDetails}
        );

        this.$emit('onUpdateDealOnly', resp?.data?.data);

        this.showModal = false;

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    }
  }
}
</script>

<style scoped>

</style>
