<template>
  <card class="mr-2 shadow" body-classes="standard-card-body">
    <no-data-component :label="$t('common.no_commissions')" v-if="commissionsToShow.length === 0"/>
    <template v-if="commissionsToShow.length > 1">
      <ul class="list-group list-group-flush">
        <li
          v-for="(commission, index) in commissionsToShow"
          v-bind:key="`commission-${index}`"
          class="standard-li">
          <div class="mr-3 border-right pr-2">
            {{commission.month}}/{{commission.year}}
          </div>
          <div class="standard-label text-uppercase flex-grow-1">
            <div>{{$t(`balance.reasons.${commission.reason}`)}}</div>
            <div>{{ users[commission.user_id] | optional('lastname') }} {{ users[commission.user_id] | optional('firstname') }}</div>
          </div>
          <div class="mr-auto">
            <label-theme-component>{{commission.amount | euro}}</label-theme-component>
          </div>
        </li>
      </ul>
    </template>

  </card>
</template>

<script>

import {mapGetters} from "vuex";
import NoDataComponent from "@/components/NoDataComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent.vue";
import {permissionService} from "@/util/permission-service";
import _ from "lodash";

export default {
  name: "CommissionsRecap",
  components: {
    LabelThemeComponent,
    NoDataComponent,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      currentUser: 'auth/currentUser',
    }),

    commissionsToShow() {
      return _.filter(this.commissions, (commission) => {
        if(permissionService.isAllowed([permissionService.ACCOUNTANT_ROLE])) {
          return true;
        }

        return commission.user_id === this.currentUser.id;

      })
    }
  },
  props: {
    commissions: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    canShowCommission(commission) {

      return true;
      // return permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE]);
    }
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
</style>
