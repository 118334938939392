var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('deal-payment-summary',{attrs:{"payments":_vm.paymentsLocal || [],"contract-amount":_vm.contractAmount}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('octo-notes',{attrs:{"height":"182px","entity":"deal","type":"payment","entity-id":_vm.deal.id,"notes":_vm.deal.paymentNotes,"available-tags":['deal','student']}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('card',{staticClass:"mr-2 shadow",attrs:{"body-classes":"standard-card-body"}},[_c('el-table',{attrs:{"stripe":"","data":_vm.paymentsLocal}},[_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.reason'),"width":200,"fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(row.reason ? _vm.$t('deal.payment_reasons.' + row.reason) : '')+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.amount'),"fixed":"","width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("euro")(row.amount))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.deadline'),"width":180},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.deadline))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[(row.status)?_c('badge',{staticClass:"text-uppercase",attrs:{"type":_vm.paymentStatusConst[row.status].color}},[_vm._v(" "+_vm._s(_vm.$t('deal.payment_statuses.' + row.status))+" ")]):_vm._e()],1)}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.expiration_date'),"width":200},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.agreed_deadline))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.payment_date'),"width":180},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.payment_date))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.payment_type'),"width":250},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(row.payment_type ? _vm.$t('deal.payment_types.' + row.payment_type) : '')+" ")])}}])}),_c('el-table-column',{attrs:{"align":"right","width":"120","fixed":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('base-button',{attrs:{"size":"sm","link":"","icon":""},on:{"click":function($event){return _vm.openModal({}, -1)}}},[_c('octo-icon',{attrs:{"icon":"add"}})],1),_c('base-button',{attrs:{"link":"","icon":"","size":"sm","disabled":_vm.paymentsLocal && _vm.paymentsLocal.length > 0},on:{"click":_vm.openGeneratorModal}},[_c('octo-icon',{attrs:{"icon":"restore"}})],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return _c('div',{staticClass:"d-flex justify-content-end"},[_c('base-button',{staticClass:"mx-1",attrs:{"icon":"","link":"","size":"sm"},on:{"click":function($event){return _vm.openModal(row, $index)}}},[_c('octo-icon',{attrs:{"icon":"edit"}})],1),_c('base-button',{staticClass:"mx-1",attrs:{"icon":"","link":"","type":"danger","size":"sm"},on:{"click":function($event){return _vm.deletePayment(row, $index)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1)],1)}}])}),_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t('datatable.no_result'))+" ")])],2)],1)],1)]),_c('modal',{attrs:{"bodyClasses":"px-2 py-0","centered":"","show":_vm.showModal},on:{"update:show":function($event){_vm.showModal=$event}}},[_c('h5',{staticClass:"modal-title text-uppercase",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t('deal.payment'))+" ")]),_c('deal-payment-tab-form',{key:("generator-form-" + _vm.formKey),ref:"dealPaymentTabForm",attrs:{"payment":_vm.currentPayment}}),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"link":""},on:{"click":_vm.saveCurrentPayment}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],2),_c('modal',{attrs:{"bodyClasses":"px-2 py-0","centered":"","show":_vm.showGeneratorModal},on:{"update:show":function($event){_vm.showGeneratorModal=$event}}},[_c('h5',{staticClass:"modal-title text-uppercase",attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.$t('deal.generate_payments'))+" ")]),_c('deal-payment-generator-form',{key:("generator-form-" + _vm.formKey),ref:"dealPaymentGeneratorForm"}),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"link":""},on:{"click":_vm.generatePayments}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }