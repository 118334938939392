var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"shadow",attrs:{"body-classes":"standard-card-body"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('common.documents')))])]),_c('el-table',{attrs:{"stripe":"","data":_vm.media}},[_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.file_type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('label-theme-component',[_vm._v(_vm._s(_vm.$t('filetag.' + row.tag)))])],1)}}])}),_c('el-table-column',{attrs:{"align":"left","label":_vm.$t('fields.filename')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.filename)+"."+_vm._s(row.extension)+" ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('fields.size')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.size / 1000)+" kb ")])}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('common.upload_date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("date")(row.created_at))+" ")])}}])}),_c('el-table-column',{attrs:{"align":"right","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"text-truncate"},[_c('a',{attrs:{"href":row.url,"target":"_blank"}},[_c('base-button',{staticClass:"mx-1",attrs:{"link":"","icon":"","size":"sm"}},[_c('octo-icon',{attrs:{"icon":"download"}})],1)],1),(_vm.permissionService.isAllowed([_vm.permissionService.ADMIN_ROLE]))?_c('base-button',{staticClass:"mx-1",attrs:{"link":"","icon":"","size":"sm","confirm-required":"","confirm-text":_vm.$t('confirm.delete_document')},on:{"click":function($event){return _vm.deleteMedia(row.id)}}},[_c('octo-icon',{attrs:{"icon":"wrong"}})],1):_vm._e()],1)}}])}),_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t('datatable.no_result'))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }