import { render, staticRenderFns } from "./DealAccountingCard.vue?vue&type=template&id=678cb569&scoped=true&"
import script from "./DealAccountingCard.vue?vue&type=script&lang=js&"
export * from "./DealAccountingCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678cb569",
  null
  
)

export default component.exports