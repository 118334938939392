import Deal from "@/models/deal";

const Payment = {
  id: null,
  deal_id: null,
  payment_date: null,
  deadline: null,
  description: null,
  payment_type: null,
  status: null,
  reason: null,
  agreed_deadline: null,
  amount: null,
  deal: Deal
};

export default Payment
