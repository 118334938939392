<template>
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-12 col-md-6">
        <deal-payment-summary :payments="paymentsLocal || []" :contract-amount="contractAmount"/>
      </div>
      <div class="col-12 col-md-6">
        <octo-notes
            height="182px"
            entity="deal"
            type="payment"
            :entity-id="deal.id"
            :notes="deal.paymentNotes"
            :available-tags="['deal','student']"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card body-classes="standard-card-body" class="mr-2 shadow">
          <el-table stripe :data="paymentsLocal">
            <el-table-column align="left" :label="$t('fields.reason')" :width="200" fixed>
              <div slot-scope="{ row }" class="text-truncate">
                {{ row.reason ? $t('deal.payment_reasons.' + row.reason) : '' }}
              </div>
            </el-table-column>
            <el-table-column align="center" :label="$t('fields.amount')" fixed :width="150">
              <div slot-scope="{ row }">
                {{ row.amount | euro }}
              </div>
            </el-table-column>
            <el-table-column align="center" :label="$t('fields.deadline')" :width="180">
              <div slot-scope="{ row }">
                {{ row.deadline | date }}
              </div>
            </el-table-column>
            <el-table-column align="center" :label="$t('fields.status')">
              <div slot-scope="{ row }">
                <badge v-if="row.status" :type="paymentStatusConst[row.status].color" class="text-uppercase">
                  {{ $t('deal.payment_statuses.' + row.status) }}
                </badge>
              </div>
            </el-table-column>
            <el-table-column align="center" :label="$t('fields.expiration_date')" :width="200">
              <div slot-scope="{ row }">
                {{ row.agreed_deadline | date }}
              </div>
            </el-table-column>
            <el-table-column align="center" :label="$t('fields.payment_date')" :width="180">
              <div slot-scope="{ row }">
                {{ row.payment_date | date }}
              </div>
            </el-table-column>
            <el-table-column align="left" :label="$t('fields.payment_type')" :width="250">
              <div slot-scope="{ row }" class="text-truncate">
                {{ row.payment_type ? $t('deal.payment_types.' + row.payment_type) : '' }}
              </div>
            </el-table-column>

            <el-table-column align="right" width="120" fixed="right">
              <template slot="header" slot-scope="scope">
                <base-button size="sm" link icon @click="openModal({}, -1)">
                  <octo-icon icon="add"/>
                </base-button>
                <base-button
                  link
                  icon
                  size="sm"
                  :disabled="paymentsLocal && paymentsLocal.length > 0"
                  @click="openGeneratorModal"
                >
                  <octo-icon icon="restore"/>
                </base-button>
              </template>
              <div slot-scope="{ row, $index }" class="d-flex justify-content-end">
                <base-button icon link class="mx-1" size="sm" @click="openModal(row, $index)">
                  <octo-icon icon="edit"/>
                </base-button>
                <base-button icon link class="mx-1" type="danger" size="sm" @click="deletePayment(row, $index)">
                  <octo-icon icon="wrong"/>
                </base-button>
              </div>
            </el-table-column>
            <template slot="empty">
              {{ $t('datatable.no_result') }}
            </template>
          </el-table>
        </card>
      </div>
    </div>

    <modal bodyClasses="px-2 py-0" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('deal.payment') }}
      </h5>
      <deal-payment-tab-form :payment="currentPayment" :key="`generator-form-${formKey}`" ref="dealPaymentTabForm"/>
      <template slot="footer">
        <base-button link @click="saveCurrentPayment">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>

    <modal bodyClasses="px-2 py-0" centered :show.sync="showGeneratorModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('deal.generate_payments') }}
      </h5>
      <deal-payment-generator-form :key="`generator-form-${formKey}`" ref="dealPaymentGeneratorForm"/>
      <template slot="footer">
        <base-button link @click="generatePayments">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import BaseTable from "@/components/BaseTable";
import Phone from "@/models/phone";
import {MessageBox, Table, TableColumn} from 'element-ui';
import DealPaymentTabForm from "@/pages/Deals/components/DealPaymentTabForm";
import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import Payment from "@/models/payment";
import Modal from "@/components/Modal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Badge from "@/components/Badge";
import PaymentStatusConst from "@/pages/Deals/resources/paymentStatusConst";
import DealHolderData from "@/pages/Deals/components/DealHolderData";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import DealPaymentSummary from "@/pages/Deals/components/DealPaymentSummary";
import OctoNotes from "@/components/OctoNotes";
import DealPaymentGeneratorForm from "@/pages/Deals/components/DealPaymentGeneratorForm";

export default {
  name: "DealPaymentTab",
  components: {
    DealPaymentGeneratorForm,
    OctoNotes,
    DealPaymentSummary,
    ListGroupItemComponent,
    DealHolderData,
    Badge,
    LabelThemeComponent,
    OctoIcon,
    Modal,
    DealPaymentTabForm,
    BaseTable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      paymentsLocal: [],
      currentPayment: Phone,
      currentPaymentIndex: 0,
      formKey: 0,
      showModal: false,
      showGeneratorModal: false,
      paymentStatusConst: PaymentStatusConst,
      contractAmount: 0

    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    }
  },
  mounted() {
    this.paymentsLocal = this.$_.cloneDeep(this.deal.payments);
    this.contractAmount = this.deal.dealExtraInfo?.contract_amount || 0;
  },
  methods: {
    openModal(payment, index) {
      this.currentPaymentIndex = index;
      this.currentPayment = this.$_.isEmpty(payment) ? this.$_.cloneDeep(Payment) : payment;
      this.showModal = true;
      this.formKey++;
    },

    saveCurrentPayment: async function () {
      try {
        const payment = await this.$refs.dealPaymentTabForm.validate();
        this.$fullLoading.show();

        if (payment?.id) {
          const resp = await this.$api.put(
            endpoints.PAYMENT_UPDATE.replace('{id}', payment.id),
            payment
          );

          this.$set(this.paymentsLocal, this.currentPaymentIndex, resp?.data?.data);
        } else {
          const resp = await this.$api.post(
            endpoints.PAYMENT_STORE_BY_DEAL.replace('{dealId}', this.deal.id),
            payment
          );

          this.paymentsLocal.push(resp?.data?.data);
        }

        this.paymentsLocal = this.$_.sortBy(this.paymentsLocal, ['deadline']);

        this.showModal = false;
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    deletePayment: function (item, index) {
      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_payment'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.$fullLoading.show();
          this.$api.delete(endpoints.PAYMENT_DELETE.replace('{id}', item.id))
            .then(() => {
              this.paymentsLocal.splice(index, 1);
            })
            .catch(() => {
              this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
            })
            .finally(() => {
              this.$fullLoading.hide();
            });
        })
        .catch(() => {
        });
    },

    openGeneratorModal() {
      this.formKey++;
      this.showGeneratorModal = true;
    },

    generatePayments: async function () {
      try {
        this.$fullLoading.show()

        const firstDeadline = await this.$refs.dealPaymentGeneratorForm.validate();

        const resp = await this.$api.post(
          endpoints.PAYMENT_GENERATE_PAYMENTS_BY_DEAL.replace('{dealId}', this.deal.id),
          {first_deadline: firstDeadline}
        )

        this.paymentsLocal.push(...resp?.data?.data);
        this.paymentsLocal = this.$_.sortBy(this.paymentsLocal, ['deadline']);
        this.showGeneratorModal = false;

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.data_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
