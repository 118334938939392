<template>
  <card body-classes="standard-card-body" class="shadow">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('common.documents') }}</h4>
    </div>
    <el-table stripe :data="media">
      <el-table-column align="left" :label="$t('fields.file_type')">
        <div slot-scope="{ row }">
          <label-theme-component>{{ $t('filetag.' + row.tag) }}</label-theme-component>
        </div>
      </el-table-column>
      <el-table-column align="left" :label="$t('fields.filename')">
        <div slot-scope="{ row }">
          {{ row.filename }}.{{ row.extension }}
        </div>
      </el-table-column>
      <el-table-column align="center" :label="$t('fields.size')">
        <div slot-scope="{ row }">
          {{ row.size / 1000 }} kb
        </div>
      </el-table-column>
      <el-table-column align="center" :label="$t('common.upload_date')">
        <div slot-scope="{ row }">
          {{ row.created_at | date }}
        </div>
      </el-table-column>
      <el-table-column align="right" width="150">
        <div slot-scope="{ row }" class="text-truncate">
          <a :href="row.url" target="_blank">
            <base-button link icon class="mx-1" size="sm">
              <octo-icon icon="download"/>
            </base-button>
          </a>
          <base-button
            link
            icon
            class="mx-1"
            size="sm"
            confirm-required
            :confirm-text="$t('confirm.delete_document')"
            v-if="permissionService.isAllowed([permissionService.ADMIN_ROLE])"
            @click="deleteMedia(row.id)"
          >
            <octo-icon icon="wrong"/>
          </base-button>
        </div>
      </el-table-column>
      <template slot="empty">
        {{ $t('datatable.no_result') }}
      </template>
    </el-table>
  </card>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Badge from "@/components/Badge";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {permissionService} from "@/util/permission-service";
import {endpoints} from "@/routes/endpoints";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {Table, TableColumn} from "element-ui";

export default {
  name: "DealDocumentList",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    Badge,
    OctoIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      permissionService: permissionService
    }
  },
  props: {
    media: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    deleteMedia(id) {
      this.$fullLoading.show();
      this.$api.delete(endpoints.MEDIA_DELETE.replace('{id}', id))
        .then(() => {
          this.$emit('onDeleteMedia', id);
          this.$notify({type: 'success', message: this.$t('notifications.delete_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.delete_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    }
  }
}
</script>

<style scoped>

</style>
