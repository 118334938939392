<template>
  <card class="shadow" body-classes="standard-card-body">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.confirmed_date')">
        <template slot="value">
          <label-theme-component v-if="readOnly">
            {{ dealExtraInfo.confirmed_date | date }}
          </label-theme-component>
          <base-input v-if="!readOnly" class="mb-0 flex-grow-1">
            <el-date-picker
              type="date"
              v-model="dealExtraInfo.confirmed_date"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              :placeholder="$t('common.select_day')"
              :picker-options="{firstDayOfWeek: 1}"
            />
          </base-input>
        </template>
      </list-group-item-component>
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.accounting_status')">
        <template slot="value">
          <badge v-if="readOnly && deal.accounting_status"
                 :type="dealAccountingStatus[deal.accounting_status].color" class="text-uppercase">
            {{ $t('deal.accounting_statuses.' + deal.accounting_status) }}
          </badge>
          <base-input v-if="!readOnly" class="mb-0 flex-grow-1">
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="deal.accounting_status"
            >
              <el-option
                v-for="(status, index) in statusOptions"
                v-bind:key="`accounting_status-${index}`"
                class="select-default text-uppercase"
                :value="status"
                :label="$t('deal.accounting_statuses.' + status)"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>
    </ul>
  </card>
</template>

<script>

import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import DealExtraInfo from "@/models/dealExtraInfo";
import Deal from "@/models/deal";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {Option, Select, DatePicker} from "element-ui";
import Badge from "@/components/Badge";

export default {
  name: "DealConfirmedDate",
  components: {
    ListGroupTitleSectionComponent,
    ListSelectorComponent,
    LabelThemeComponent,
    ListGroupItemComponent,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    Badge
  },
  data() {
    return {
      labelWidth: 200,
      dealAccountingStatus: DealAccountingStatus,
      statusOptions: [
        DealAccountingStatus.active.value,
        DealAccountingStatus.unsolved.value,
        DealAccountingStatus.paid.value,
        DealAccountingStatus.excerpt_closure.value,
      ]
    }
  },
  props: {
    dealExtraInfo: {
      type: Object,
      default: () => _.cloneDeep(DealExtraInfo)
    },
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
