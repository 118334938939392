<template>
  <ValidationObserver ref="dealFinancialHolderForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.businessname')">
        <template slot="value">
          <ValidationProvider
            name="businessname"
            rules="required"
            v-slot="{ passed, failed, errors }"
            class="flex-grow-1"
          >
            <base-input
              v-model="dealFinancialHolderLocal.businessname"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.unique_code')">
        <template slot="value">
          <ValidationProvider
            name="unique_code"
            rules="required"
            v-slot="{ passed, failed, errors }"
            class="flex-grow-1"
          >
            <base-input
              v-model="dealFinancialHolderLocal.unique_code"
              type="text"
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.codfis')">
        <template slot="value">
          <base-input
            v-model="dealFinancialHolderLocal.codfis"
            type="text"
            maxlength="16"
            class="mb-0 flex-grow-1"
          />
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.piva')">
        <template slot="value">
          <base-input v-model="dealFinancialHolderLocal.piva" type="text" class="mb-0 flex-grow-1"/>
        </template>
      </list-group-item-component>
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.email')">
        <template slot="value">
          <base-input v-model="dealFinancialHolderLocal.email" type="text" class="mb-0 flex-grow-1"/>
        </template>
      </list-group-item-component>

    </ul>
  </ValidationObserver>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import DealFinancialHolder from "@/models/dealFinancialHolder";

export default {
  name: "DealHolderFinancialForm",
  components: {ListGroupItemComponent},
  data() {
    return {
      dealFinancialHolderLocal: this.$_.cloneDeep(DealFinancialHolder),
      labelWidth: 140,
    }
  },
  props: {
    dealFinancialHolder: {
      type: Object,
      default: () => _.cloneDeep(DealFinancialHolder)
    }
  },
  mounted() {
      this.dealFinancialHolderLocal = this.$_.cloneDeep(this.dealFinancialHolder);
  },
  methods: {
    validate: function () {
      return new Promise((resolve, reject) => {
        this.$refs.dealFinancialHolderForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.dealFinancialHolderLocal);
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
