<template>
  <ValidationObserver ref="dealExtraInfoForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label-width="labelWidth" :label="$t('fields.contract_amount')">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="contract_amount"
            rules="min_value:0"
            v-slot="{ passed, failed, errors }"
          >
            <base-input class="mb-0"
                        type="number"
                        v-model="dealExtraInfoLocal.contract_amount"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.payment_type')">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              clearable
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealExtraInfoLocal.payment_type"
            >
              <el-option
                v-for="(option, index) in paymentOptions"
                v-bind:key="`payment-type-${index}`"
                class="select-default text-uppercase"
                :value="option"
                :label="$t('deal.payment_types.' + option)"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.installment_plan')">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              clearable
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealExtraInfoLocal.installment_plan"
            >
              <el-option
                class="select-default text-uppercase"
                v-for="(option, index) in installmentPlans"
                v-bind:key="`installment-plan-${index}`"
                :value="option"
                :label="option === '1' ? $t('deal.single_installment') : `${option} ${$t('deal.installments')}`"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.enrollment_amount')">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="enrollment_amount"
            rules="min_value:0"
            v-slot="{ passed, failed, errors }"
          >
            <base-input class="mb-0"
                        type="number"
                        v-model="dealExtraInfoLocal.enrollment_amount"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.enrollment_payment_type')">
        <template slot="value">
          <base-input class="mb-0 flex-grow-1">
            <el-select
              clearable
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealExtraInfoLocal.enrollment_payment_type"
            >
              <el-option
                class="select-default text-uppercase"
                v-for="(option, index) in paymentOptions"
                v-bind:key="`enrollment-payment-type-${index}`"
                :value="option"
                :label="$t('deal.payment_types.' + option)"
              >
              </el-option>
            </el-select>
          </base-input>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.withheld_fee')">
        <template slot="value">
          <div class="d-flex justify-content-between align-items-center">
            <el-switch class="ml-auto text-uppercase" v-model="dealExtraInfoLocal.withheld_fee"/>
          </div>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.km_traveled')">
        <template slot="value">
          <ValidationProvider
            class="flex-grow-1"
            name="km_traveled"
            rules="min_value:0"
            v-slot="{ passed, failed, errors }"
          >
            <base-input class="mb-0"
                        type="number"
                        v-model="dealExtraInfoLocal.km_traveled"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
            />
          </ValidationProvider>
        </template>
      </list-group-item-component>

      <list-group-item-component :label-width="labelWidth" :label="$t('fields.consulting_in_presence')">
        <template slot="value">
          <div class="d-flex justify-content-between align-items-center">
            <el-switch class="ml-auto text-uppercase" v-model="dealExtraInfoLocal.consulting_in_presence"/>
          </div>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import {Select, Option, Checkbox, Switch} from 'element-ui';
import DealExtraInfo from "@/models/dealExtraInfo";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import DealPaymentTypes from "../resources/dealPaymentTypes";
import OctoIcon from "../../../components/octo-icon/OctoIcon";

export default {
  name: "DealContractDataCardForm",
  components: {
    OctoIcon,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
  },
  data() {
    return {
      dealExtraInfoLocal: this.$_.cloneDeep(DealExtraInfo),
      paymentOptions: [
        DealPaymentTypes.financing_interest.value,
        DealPaymentTypes.financing_interest_free.value,
        DealPaymentTypes.transfer.value,
        DealPaymentTypes.credit_card.value,
        DealPaymentTypes.debit_card.value,
        DealPaymentTypes.cash.value,
        DealPaymentTypes.scalapay.value,
        DealPaymentTypes.soisy.value,
        DealPaymentTypes.sequra.value,
      ],
      installmentPlans: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '18', '24', '30'],
      labelWidth: 230
    }
  },
  props: {
    dealExtraInfo: {
      type: Object,
      default: () => DealExtraInfo
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.$_.isEmpty(this.dealExtraInfo)) {
      this.dealExtraInfoLocal = this.$_.cloneDeep(this.dealExtraInfo);
    }

    this.dealExtraInfoLocal.installment_plan = this.dealExtraInfoLocal.installment_plan || '1';
  },
  methods: {
    getDealExtraInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.dealExtraInfoForm.validate()
          .then((success) => {
            if (success) {
              resolve(this.dealExtraInfoLocal);
            } else {
              this.$notify({
                type: 'danger',
                message: this.$t('notifications.cannot_update_deal_with_invalid_contract_data')
              });
              reject();
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          });
      });
    },
  }
}
</script>

<style scoped>

</style>
