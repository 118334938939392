const PaymentTypeConst = {
  credit_card_small_pay: {
    value: 'credit_card_small_pay',
  },
  credit_card_my_pos: {
    value: 'credit_card_my_pos',
  },
  cash: {
    value: 'cash',
  },
  financing_interest_free: {
    value: 'financing_interest_free',
  },
  financing_interest: {
    value: 'financing_interest',
  },
  transfer: {
    value: 'transfer',
  },
  debit_card: {
    value: 'debit_card',
  },
  postal_order: {
    value: 'postal_order',
  },
  soisy: {
    value: 'soisy',
  },
  scalapay: {
    value: 'scalapay',
  },
  sequra: {
    value: 'sequra',
  },
}

export default PaymentTypeConst
