<template>
  <div>
    <card v-if="!readOnly" class="shadow" body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('deal.upload_documents') }}</h4>
      </div>
      <octo-upload-cloud-updated
        show-full-loading
        :presigned-url-action="endpoints.DEAL_DOCUMENT_PRESIGNED_URL.replace('{id}', deal.id)"
        :store-url-action="endpoints.DEAL_STORE_DOCUMENT.replace('{id}', deal.id)"
        :tag-list="dealDocumentLabels"
        @onSuccessUpload="successUpload"
      />
    </card>
    <deal-document-list :media="localMedia" @onDeleteMedia="deleteMedia"/>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import Badge from "@/components/Badge";
import DealDocumentList from "./DealDocumentList";
import DealDocumentLabels from "../resources/dealDocumentLabels";
import OctoUploadCloudUpdated from "@/components/octo-upload-cloud/OctoUploadCloudUpdated";

export default {
  name: "DealDocumentsUpdated",
  components: {OctoUploadCloudUpdated, DealDocumentList, Badge, BaseAlert, OctoIcon},
  data() {
    return {
      endpoints: endpoints,
      localMedia: [],
      dealDocumentLabels: DealDocumentLabels
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.localMedia = this.$_.cloneDeep(this.deal.media);
  },
  methods: {
    successUpload: function (media) {
      this.localMedia.unshift(media);
    },
    deleteMedia(id) {
      this.localMedia = this.$_.filter(this.localMedia, media => {
        return media.id !== id
      });
    }
  }
}
</script>

<style scoped>

</style>
