var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"dealFinancialHolderForm"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.businessname')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"businessname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.dealFinancialHolderLocal.businessname),callback:function ($$v) {_vm.$set(_vm.dealFinancialHolderLocal, "businessname", $$v)},expression:"dealFinancialHolderLocal.businessname"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.unique_code')}},[_c('template',{slot:"value"},[_c('ValidationProvider',{staticClass:"flex-grow-1",attrs:{"name":"unique_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{staticClass:"mb-0",class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"type":"text","error":errors[0]},model:{value:(_vm.dealFinancialHolderLocal.unique_code),callback:function ($$v) {_vm.$set(_vm.dealFinancialHolderLocal, "unique_code", $$v)},expression:"dealFinancialHolderLocal.unique_code"}})]}}])})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.codfis')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1",attrs:{"type":"text","maxlength":"16"},model:{value:(_vm.dealFinancialHolderLocal.codfis),callback:function ($$v) {_vm.$set(_vm.dealFinancialHolderLocal, "codfis", $$v)},expression:"dealFinancialHolderLocal.codfis"}})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.piva')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1",attrs:{"type":"text"},model:{value:(_vm.dealFinancialHolderLocal.piva),callback:function ($$v) {_vm.$set(_vm.dealFinancialHolderLocal, "piva", $$v)},expression:"dealFinancialHolderLocal.piva"}})],1)],2),_c('list-group-item-component',{attrs:{"label-width":_vm.labelWidth,"label":_vm.$t('fields.email')}},[_c('template',{slot:"value"},[_c('base-input',{staticClass:"mb-0 flex-grow-1",attrs:{"type":"text"},model:{value:(_vm.dealFinancialHolderLocal.email),callback:function ($$v) {_vm.$set(_vm.dealFinancialHolderLocal, "email", $$v)},expression:"dealFinancialHolderLocal.email"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }