<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.deal') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-12">
                <octo-header-button
                  :buttons="headerButtons"
                  :title="registry.surname + ' ' + registry.name"
                  @onChangeOperator="showOperatorsModal = true"
                  @onOpenOctoEmail="openOctoEmail('deal')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <deal-card
                  :deal="deal"
                  :read-only="dealCardIsReadOnly"
                  :available-transitions="availableTransitions"
                  :key="`deal-card-${updateAfterMountedKey}`"
                  @onUpdateDealData="updateDealData"
                />
              </div>
              <div class="col-md-5">
                <deal-status-container
                  :deal="deal"
                  :key="`dealStatusContainer-${updateAfterMountedKey}`"
                />
                <deal-history :deal="deal" :key="`dealHistory-${updateAfterMountedKey}`"/>
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <octo-header-button
              :title="registry.surname + ' ' + registry.name"
              :buttons="leadButtons"
              @onOpenLead="$router.push({name: 'leads.archive.show', params: {id: deal.lead.id}})"
            />
            <lead-tab-pane :lead="deal.lead" :key="`lead-tab-pane-${updateAfterMountedKey}`" :read-only="false"/>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :title="registry.surname + ' ' + registry.name"
              :buttons="registryButtons"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: registry.id}})"
            />
            <registry-tab-pane
              :registry="registry"
              :key="`registry-tab-pane-${updateAfterMountedKey}`"
              v-on:onSetEmails="handleOnSetEmails"
            />
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.documents') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-documents-updated
              :read-only="isBalanced"
              :deal="deal"
              :key="`deal-documents-${updateAfterMountedKey}`"
            />
          </tab-pane>

          <tab-pane v-if="showScheduler">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.organizer') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <deal-date-archive-scheduler
              :key="schedulerKey"
              :operator="currentUser"
              :deal="deal"
              :deal-dates="deal.dealDates || []"
              :date-min="dateMin"
              :registry="registry"
              :sales="sales"
              @onDeleteDealDate="deleteDealDate"
              @onAddDealDate="addDealDate"
              @onUpdateDealDate="updateDealDate"
              ref="dealDateToRecallScheduler"
            />
          </tab-pane>

          <tab-pane v-if="showContractData">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.courses') }}</div>
              </div>
            </template>
            <octo-header-button :title="registry.surname + ' ' + registry.name"/>
            <div class="row">
              <div class="col-12">
            <deal-contract-data-card
              :deal="deal"
              :key="`deal-datum-form-${updateAfterMountedKey}`"
              @onUpdateDealOnly="updateDealOnly"
            />
              </div>
              <div class="col-12">
                <deal-holder-data :deal="deal" :key="updateAfterMountedKey"/>
              </div>
              <div class="col-12 col-md-6">
            <deal-courses-card
              :deal="deal"
              :read-only="readOnlyCoursesCard"
              :key="`deal-courses-card-${updateAfterMountedKey}`"
              @onUpdateDealOnly="updateDealOnly"
            />
              </div>
              <div class="col-12 col-md-6">
            <octo-notes
              entity="deal"
              type="course"
              :entity-id="deal.id"
              :notes="deal.courseNotes"
              :available-tags="['course']"
              :key="`course-notes-${updateAfterMountedKey}`"
            />
              </div>
              </div>
          </tab-pane>

          <tab-pane v-if="showStudents">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('didactics.students') }}</div>
              </div>
            </template>
            <registry-students :registry="deal.registry" :key="`registry-students-${updateAfterMountedKey}`"/>
          </tab-pane>

          <tab-pane v-if="showAccountingData">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('deal.accounting_data') }}</div>
              </div>
            </template>
            <octo-header-button
                :title="registry.surname + ' ' + registry.name"
            />

            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-6">
                  <deal-accounting-card
                    :deal="deal"
                    :key="`deal-accounting-card-${updateAfterMountedKey}`"
                    @onUpdateDealOnly="updateDealOnly"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <commissions-recap :commissions="commissions"/>
                </div>
                <div class="col-12">
                  <deal-holder-data :deal="deal" :key="updateAfterMountedKey"/>
                </div>
              </div>
            </div>

          </tab-pane>

          <tab-pane v-if="showNotes">
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('fields.notes') }}</div>
              </div>
            </template>
            <octo-header-button
                :title="registry.surname + ' ' + registry.name"
            />
            <octo-notes
                read-only
                entity="deal"
                :entity-id="deal.id"
                :notes="deal.allNotes"
                :key="`all-notes-${updateAfterMountedKey}`"
            />
          </tab-pane>
        </tabs>

      </div>
    </div>

    <modal bodyClasses="p-1" :show.sync="showOperatorsModal" centered modalClasses="modal-lg">
      <template slot="header">
        <h5 class="modal-title text-uppercase">{{ $t('common.change_operator') }}</h5>
      </template>
      <list-selector-component exclusive :number-items-per-page="8" :list="operators" ref="listSelectorComponent">
        <template v-slot:default="slotProps">
          <div>
            {{ users[slotProps.item.id] | optional('lastname') }}
            {{ users[slotProps.item.id] | optional('firstname') }}
          </div>
        </template>
      </list-selector-component>
      <template slot="footer">
        <base-button
          link
          confirm-required
          class="text-uppercase"
          :confirm-text="$t('confirm.change_expired_deal_operator_text')"
          @click="changeOperator"
        >
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>

    <octo-email
      :target-id="deal.id"
      :target-type="emailTargetType.DEAL"
      :emails="registry.emails"
      :allowed-email-templates="allowedEmailTemplates"
      :show-modal="showEmailModal"
      :key="`email-modal-${modalKey}`"
      @onClose="showEmailModal = false"
      @emailSent="updateAfterMountedKey++; showEmailModal = false"
    />
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import Registry from "@/models/registry";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealSummary from "@/pages/Deals/components/DealSummary";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealSummaryGeneral from "@/pages/Deals/components/DealSummaryGeneral";
import DealCoursePicker from "@/pages/Deals/components/DealCoursePicker";
import DealContractDataCard from "@/pages/Deals/components/DealContractDataCard";
import {permissionService} from "@/util/permission-service";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import DealConfirmedDate from "@/pages/Deals/components/DealConfirmedDate";
import DealExtraInfo from "@/models/dealExtraInfo";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import DealDateArchiveScheduler from "@/components/DealDateScheduler/DealDateArchiveScheduler";
import {mapGetters} from "vuex";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import DealPaymentTab from "@/pages/Deals/components/DealPaymentTab";
import DealCard from "@/pages/Deals/components/DealCard";
import DealCoursesCard from "@/pages/Deals/components/DealCoursesCard";
import DealDocumentsUpdated from "@/pages/Deals/components/DealDocumentsUpdated";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";
import Modal from "@/components/Modal";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import dealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealStudents from "@/pages/Deals/components/DealStudents";
import OctoEmail, {EMAIL_TEMPLATE_NAME, EMAIL_TARGET_TYPE} from "@/components/octo-email/OctoEmail";
import DealAccountingCard from "./components/DealAccountingCard";
import DealHistory from "@/pages/Deals/components/DealHistory";
import OctoNotes from "@/components/OctoNotes.vue";
import DealHolderData from "@/pages/Deals/components/DealHolderData.vue";
import RegistryStudents from "@/pages/Students/components/RegistryStudents.vue";
import CommissionsRecap from "@/pages/PostSelling/components/CommissionsRecap.vue";

export default {
  name: "ShowDealArchivePage",
  components: {
    CommissionsRecap,
    RegistryStudents,
    DealHolderData,
    OctoNotes,
    DealHistory,
    DealAccountingCard,
    OctoEmail,
    DealStudents,
    ListSelectorComponent,
    Modal,
    DealDocumentsUpdated,
    DealCoursesCard,
    DealCard,
    DealPaymentTab,
    LeadTabPane,
    DealDateArchiveScheduler,
    RegistryTabPane,
    DealConfirmedDate,
    OctoHeaderButton,
    DealContractDataCard,
    DealCoursePicker,
    DealSummaryGeneral,
    DealSummary,
    DealStatusContainer,
    RegistryPersonalData,
    OctoIcon,
    Tabs,
    TabPane,
    LeadHistory
  },
  data() {
    return {
      endpoints: endpoints,
      deal: this.$_.cloneDeep(Deal),
      registry: this.$_.cloneDeep(Registry),
      updateAfterMountedKey: 1,
      schedulerKey: 1,
      dealStatuses: DealStatuses,
      isBalanced: false,
      permissionService: permissionService,
      availableTransitions: [],
      showOperatorsModal: false,
      showEmailModal: false,
      modalKey: 0,
      paymentButtons: [{label: 'send_email', onClick: 'onOpenOctoEmail'}],
      allowedEmailTemplates: [],
      emailTargetType: EMAIL_TARGET_TYPE,
      commissions: [],
      showScheduler:false,
      showContractData:false,
      showNotes:false,
      showAccountingData:false,
      showStudents:false,
    }
  },
  async beforeMount() {
    try {
      this.$fullLoading.show();
      const resp = await this.$api.get(endpoints.DEAL_SHOW.replace('{id}', this.$route.params.id));
      this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
      if (!this.deal.dealExtraInfo) {
        this.deal.dealExtraInfo = this.$_.cloneDeep(DealExtraInfo);
      }
      this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);
      this.isBalanced = resp?.data?.isBalanced || false;
      this.availableTransitions = resp?.data?.availableTransitions || [];
      this.commissions = resp?.data?.commissions || [];

      this.showScheduler = this.permissionService.isAllowed([permissionService.CALL_CENTER_OPERATOR_ROLE])
          && this.$_.includes([
              dealStatuses.confirmed.value,
              dealStatuses.not_confirmed.value,
              dealStatuses.recall.value,
              dealStatuses.pending.value
            ],
            this.deal.status
          );

      this.showContractData = this.permissionService.isAllowed([
          permissionService.SALES_ROLE,
          permissionService.TEACHING_MANAGER_ROLE
        ]);

      this.showNotes = this.permissionService.isAllowed([
          permissionService.ACCOUNTANT_ROLE,
          permissionService.TEACHING_MANAGER_ROLE
        ]);

      this.showStudents = this.permissionService.isAllowed([
          permissionService.ACCOUNTANT_ROLE,
          permissionService.SALES_ROLE,
          permissionService.TEACHING_MANAGER_ROLE
        ]) && this.deal.status === dealStatuses.promoted.value;

      this.showAccountingData = this.permissionService.isAllowed([
          permissionService.ADMIN_ROLE,
          permissionService.ACCOUNTANT_ROLE,
          permissionService.SALES_ROLE
        ]) && (this.deal.status === dealStatuses.promoted.value || this.deal.status === dealStatuses.confirmed.value);

      this.updateAfterMountedKey++;
    } catch (e) {
      this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      this.$router.push({name: "deals.archive"});
    }  finally {
      this.$fullLoading.hide();
    }
  },
  computed: {
    ...mapGetters({
      sales: 'common/sales',
      operators: 'common/operators',
      users: 'common/users',
      currentUser: 'auth/currentUser',
    }),

    dealCardIsReadOnly() {
      return false;
    },








    dateMin() {
      return this.$moment().toDate();
    },

    readOnlyCoursesCard() {
      return !this.permissionService.isAllowed([
        permissionService.SALES_ROLE,
        permissionService.SALES_MANAGER_ROLE,
        permissionService.CALL_CENTER_MANAGER_ROLE,
      ]) || this.deal.status === dealStatuses.promoted.value
    },

    operatorCanBeChanged() {
      return this.deal?.lead?.status !== LeadStatuses.free.value
        && this.deal?.lead?.status !== LeadStatuses.processing.value
        && this.permissionService.isAllowed([
          permissionService.SALES_MANAGER_ROLE,
          permissionService.CALL_CENTER_MANAGER_ROLE,
        ]);
    },

    headerButtons() {
      const buttons = [{
        label: 'send_email',
        onClick: 'onOpenOctoEmail'
      }];

      if (this.operatorCanBeChanged) {
        buttons.push({label: 'change_operator', onClick: 'onChangeOperator'})
      }

      return buttons;
    },

    registryButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({label: 'open', onClick: 'onOpenRegistry'});
      }

      return buttons;
    },

    leadButtons() {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({label: 'open', onClick: 'onOpenLead'});
      }

      return buttons;
    }
  },
  methods: {
    updateDealData(dealData) {
      this.deal = dealData?.data || this.$_.cloneDeep(Deal);
      this.availableTransitions = dealData?.availableTransitions || [];
      this.updateAfterMountedKey++;
    },

    deleteDealDate(item) {
      this.$set(this.deal, 'dealDates', this.$_.filter(this.deal.dealDates, (dealDate) => {
        return dealDate.id !== item.id
      }))
    },

    updateDealDate(item) {
      this.$set(
        this.deal.dealDates,
        this.$_.findIndex(this.deal.dealDates, (dealDate) => dealDate.id === item.id),
        item
      );
    },

    addDealDate(item) {
      this.$set(this.deal, 'dealDates', [...this.deal.dealDates, item])
    },

    updateDealOnly(deal) {
      this.deal = deal || this.$_.cloneDeep(Deal);
      this.updateAfterMountedKey++;
    },

    changeOperator: function () {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.LEAD_CHANGE_OPERATOR
          .replace('{id}', this.deal?.lead?.id)
          .replace('{operatorId}', this.$refs.listSelectorComponent.getSelectedItem())
      )
        .then((resp) => {
          this.$set(this.deal.lead, 'user_id', resp?.data?.user_id);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.showOperatorsModal = false;
          this.$fullLoading.hide();
        })
    },

    openOctoEmail(tab) {
      this.allowedEmailTemplates = [EMAIL_TEMPLATE_NAME.GENERIC];

      switch (tab) {
        case 'deal':
          this.allowedEmailTemplates.push(EMAIL_TEMPLATE_NAME.DEAL_DATES);
          break;
      }

      this.modalKey++;
      this.showEmailModal = true;
    },

    handleOnSetEmails(emails){
      this.registry.emails = emails;
    }
  }
}
</script>

<style scoped>

</style>
