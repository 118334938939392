<template>
  <div v-observe-visibility="(isVisible, entry) => this.showScheduler = isVisible">
    <card body-classes="standard-card-body" class="shadow">
      <deal-date-scheduler-legend v-if="enableStatuses"/>
      <deal-date-scheduler-appointment
        class="mb-2"
        @onDelete="deleteDealDateCustom"
        @onUpdate="updateDealDate"
        :other-deal-dates="dealDates"
        :deal-date="dealDateLocal"/>
      <DxScheduler
        v-if="showScheduler"
        :height="schedulerHeight"
        :time-zone="schedulerOptions.timeZone"
        :cell-duration="schedulerOptions.cellDuration"
        :start-day-hour="schedulerService.getStartDayHour()"
        :end-day-hour="schedulerService.getEndDayHour()"
        :date-serialization-format="schedulerOptions.dateSerializationFormat"
        :maxAppointmentsPerCell="schedulerOptions.maxAppointmentsPerCell"
        :groups="schedulerOptions.groups"
        :scrolling="schedulerOptions.scrolling"
        :first-day-of-week="schedulerOptions.firstDayOfWeek"
        :views="schedulerOptions.views"
        :current-view="schedulerOptions.currentView"
        :current-date="currentDate"
        :data-source="dataSource"
        :show-current-time-indicator="schedulerOptions.showCurrentTimeIndicator"
        :editing="editing"
        :min="dateMin"
        :max="dateMax"
        :onAppointmentAdded="appointmentAdded"
        :onAppointmentAdding="appointmentAdding"
        :onAppointmentClick="appointmentClick"
        :onAppointmentContextMenu="appointmentContextMenu"
        :onAppointmentDblClick="appointmentDblClick"
        :onAppointmentDeleted="appointmentDeleted"
        :onAppointmentDeleting="appointmentDeleting"
        :onAppointmentFormOpening="appointmentFormOpening"
        :onAppointmentRendered="appointmentRendered"
        :onAppointmentUpdated="appointmentUpdated"
        :onAppointmentUpdating="appointmentUpdating"
        :onCellClick="cellClick"
        :onCellContextMenu="cellContextMenu"
        :onContentReady="onContentReady"
        :onDisposing="disposing"
        :onInizialized="initialized"
        :onOptionChanged="optionChanged"
        appointment-template="AppointmentTemplateSlot"
        ref="dxScheduler"
      >
        <DxResource
          :allow-multiple="false"
          :data-source="resourcesData"
          field-expr="userId"
          label="Sale"
        />

        <DxResource
          :allow-multiple="false"
          :data-source="statusData"
          :use-color-as-default="true"
          field-expr="status"
          label="Status"
        />

        <template #AppointmentTemplateSlot="{ data }">
          <div class="position-relative">
            <div>{{ data | optional('appointmentData.text') }}</div>
            <div class="small">
              {{ data | optional('appointmentData.startDate') | time }} -
              {{ data | optional('appointmentData.endDate') | time }}
            </div>
          </div>
        </template>

        <slot name="DxResource"></slot>
      </DxScheduler>
    </card>
  </div>
</template>

<script>

import {DxResource, DxScheduler} from "devextreme-vue/scheduler";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Modal from "@/components/Modal";
import {DatePicker, Option, Select, TimeSelect} from "element-ui";
import DealDateSchedulerMixin from "@/components/DealDateScheduler/DealDateSchedulerMixin";
import {schedulerService} from "@/util/scheduler-service";
import DealDateSchedulerLegend from "@/components/DealDateScheduler/DealDateSchedulerLegend";
import DealDateSchedulerAppointment from "@/components/DealDateScheduler/DealDateSchedulerAppointment";
import {endpoints} from "@/routes/endpoints";
import Deal from "@/models/deal";
import DealDate from "@/models/dealDate";

export default {
  name: "DealDateArchiveScheduler",
  components: {
    DealDateSchedulerAppointment,
    DealDateSchedulerLegend,
    ListGroupItemComponent,
    DxScheduler,
    DxResource,
    Modal,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [DealDateSchedulerMixin],
  props: {
    dealDates: {
      type: Array,
      default: () => []
    },
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    }
  },
  computed: {},
  methods: {

    cellClick(event) {

      const startDate = this.$moment(event.cellData.startDate);
      const endDate = this.$_.cloneDeep(startDate);
      endDate.add(this.settings?.callcenter_appointment_duration || 90, 'minutes');

      const dealDate = this.$_.cloneDeep(DealDate);
      dealDate.id = Math.random().toString(36).substr(2, 9);
      dealDate.user_id = event.cellData.groups.userId;
      dealDate.date_start = startDate.format('YYYY-MM-DD') + ' ' + startDate.format('HH:mm') + ':00';
      dealDate.date_end = endDate.format('YYYY-MM-DD') + ' ' + endDate.format('HH:mm') + ':00';
      dealDate.text = (this.registry?.surname || '')
        + ' ' + (this.registry?.name || '');
      dealDate.dateable_id = this.deal.id;

      const eventParsed = this.parseDealDate(dealDate);
      eventParsed.status = this.deal.status;
      eventParsed.disabled = false;
      this.dataSource.push(eventParsed);

      this.currentTime = event.cellData.startDate;

      this.$api.post(endpoints.DEAL_DATE_STORE_BY_DEAL, dealDate)
        .then((resp) => {
          const dealDateSaved = resp?.data?.data;
          this.$_.each(this.dataSource, (item) => {
            if (item.id === dealDate.id) {
              item.id = dealDateSaved.id;
            }
          });
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
          this.$emit('onAddDealDate', dealDateSaved)
        })
        .catch((e) => {
          console.log(e);
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })

    },

    getCurrentEvent() {
      return this.dealDateLocal
    },

    deleteDealDateCustom(item) {
      this.deleteDealDate(item)
        .then(() => {
          this.$emit('onDeleteDealDate', item)
        })
        .catch();
    },

    parseDealDate(item) {
      const event = schedulerService.parseDealDate(item);
      event.disabled = !(item.dateable_type === 'Deal' && item.dateable_id === this.deal.id)
      return event;
    },
  },
}
</script>

<style scoped>

</style>
