<template>
  <ul class="list-group list-group-flush mb-3">
    <list-group-item-component>
      <template slot="label">
        <h4 class="card-title text-capitalize mb-0">{{ title }}</h4>
      </template>
    </list-group-item-component>
    <li class="list-group-item text-center" v-if="dealDetails.length === 0">
      {{ $t('common.empty_list') }}
    </li>
    <list-group-item-component
      :value="detail.detailable.price | euro"
      v-for="(detail, index) in dealDetails"
      v-bind:key="`detail-${index}`"
    >
      <template slot="label">
        <div class="standard-label text-truncate">
          <label-theme-component>{{ detail.detailable.code + ': ' }}</label-theme-component>
          {{ detail.detailable.title }}
        </div>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('deal.suggested_total')" :value="total | euro" v-if="showTotal"/>
  </ul>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "DealCourseList",
  components: {LabelThemeComponent, ListGroupItemComponent},
  props: {
    title: {
      type: String,
      default: ''
    },
    dealDetails: {
      type: Array,
      default: () => []
    },
    showTotal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    total() {
      let total = 0;

      this.$_.each(this.dealDetails, detail => {
        total += Number(detail.detailable.price);
      })

      return total;
    },
  }
}
</script>

<style scoped>

</style>
