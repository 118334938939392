<template>
  <div>
    <card class="mr-2 shadow" body-classes="standard-card-body">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('deal.accounting_data') }}</h4>
          <base-button link icon size="sm" class="ml-auto text-capitalize" @click="openModal" v-if="!readOnly">
            <octo-icon icon="edit"/>
          </base-button>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <list-group-item-component :value="deal.date_out | date" :label="$t('deal.date_end_contract')"/>
        <list-group-item-component :label="$t('fields.deal_status')">
          <template slot="value" v-if="deal.status">
            <div class="d-flex flex-column align-items-end">
              <badge :style="{backgroundColor: schedulerService.getDealStatusColor(deal.status)}"
                     class="text-uppercase" v-if="deal.status">
                {{ $t('deal.status.' + deal.status) }}
              </badge>
              <div v-if="deal.sub_status" class="small text-uppercase">
                {{ $t('deal.status.' + deal.sub_status) }}
              </div>
            </div>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.competence_date')" :value="deal.competence_date | date"/>
        <list-group-item-component :label="$t('fields.confirmed_date')">
          <template slot="value">
            <label-theme-component>
              {{ (deal.dealExtraInfo ? deal.dealExtraInfo.confirmed_date : null) | date }}
            </label-theme-component>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.accounting_status')">
          <template slot="value" >
            <badge
              v-if="deal.accounting_status"
              :type="dealAccountingStatus[deal.accounting_status].color"
              class="text-uppercase">
              {{ $t('deal.accounting_statuses.' + deal.accounting_status) }}
            </badge>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.classroom_info')" :value="deal.classroom_info"/>



        <list-group-item-component :label="$t('fields.financing_status')">
          <template slot="value">
            <template v-if="deal.dealExtraInfo && deal.dealExtraInfo.financing_status">
              <badge
                     :type="dealFinancialStatus[deal.dealExtraInfo.financing_status].color"
                     class="text-uppercase">
                {{ $t('deal.financing_statuses.' + deal.dealExtraInfo.financing_status) }}
              </badge>
            </template>
            <template v-else>
              <label-theme-component>{{$t('deal.no_financial')}}</label-theme-component>
            </template>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.debt_collection_status')">
          <template slot="value">
            <template v-if="deal.dealExtraInfo && deal.dealExtraInfo.debt_collection_status">
              <badge
                  :type="dealDebtCollectionStatus[deal.dealExtraInfo.debt_collection_status].color"
                  class="text-uppercase">
                {{ $t('deal.debt_collection_statuses.' + deal.dealExtraInfo.debt_collection_status) }}
              </badge>
            </template>
            <template v-else>
              <label-theme-component>{{$t('deal.no_debt_collection')}}</label-theme-component>
            </template>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.date_lawyer')">
          <template slot="value">
            <template v-if="deal.dealExtraInfo && deal.dealExtraInfo.lawyer_date">
              <label-theme-component>{{ deal.dealExtraInfo.lawyer_date | date}}</label-theme-component>
            </template>
          </template>
        </list-group-item-component>

      </ul>
    </card>
    <modal bodyClasses="p-1" :show.sync="showModal" centered>
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('deal.accounting_data') }}
      </h5>
      <deal-accounting-card-form :deal="deal" :key="dealAccountingFormKey" ref="dealAccountingForm"/>
      <template slot="footer">
        <base-button class="text-uppercase" link @click="updateDealAccountingData">
          {{ $t('common.save') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Deal from "@/models/deal";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import Badge from "@/components/Badge";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import Modal from "@/components/Modal";
import {endpoints} from "@/routes/endpoints";
import DealAccountingCardForm from "@/pages/Deals/components/DealAccountingCardForm";
import DealFinancialStatus from "@/pages/Deals/resources/dealFinancialStatus";
import DealDebtCollectionStatus from "@/pages/Deals/resources/dealDebtCollectionStatus";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "DealAccountingCard",
  computed: {
    schedulerService() {
      return schedulerService
    }
  },
  components: {DealAccountingCardForm, Modal, Badge, LabelThemeComponent, ListGroupItemComponent, OctoIcon},
  data() {
    return {
      dealAccountingStatus: DealAccountingStatus,
      dealFinancialStatus: DealFinancialStatus,
      dealDebtCollectionStatus: DealDebtCollectionStatus,
      showModal: false,
      dealAccountingFormKey: 0,
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => this.$_.cloneDeep(Deal)
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.dealAccountingFormKey++;
    },

    updateDealAccountingData() {
      this.$fullLoading.show();

      this.$api.put(
        endpoints.POST_SELLING_UPDATE_ACCOUNTING_DATA.replace('{id}', this.deal.id),
        this.$refs.dealAccountingForm.getDealAccountingData()
      )
        .then((resp) => {
          this.$emit('onUpdateDealOnly', resp?.data?.data);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },
  }
}
</script>

<style scoped>

</style>
