<template>
  <ValidationObserver ref="paymentForm">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.first_installment_deadline')" :label-width="labelWidth">
        <template slot="value">
          <ValidationProvider
            name="first_installment_deadline"
            class="flex-grow-1"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
            <base-input
              required
              class="mb-0"
              :error="errors[0]"
              :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]"
            >
              <el-date-picker
                type="date"
                v-model="deadlineDay"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('common.select_day')"
                :picker-options="pickerOptions"
              />
            </base-input>
          </ValidationProvider>
        </template>
      </list-group-item-component>
    </ul>
  </ValidationObserver>
</template>

<script>
import {DatePicker} from 'element-ui';
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "DealPaymentGeneratorForm",
  data() {
    return {
      deadlineDay: this.$moment().format('YYYY-MM-DD'),
      labelWidth: 150,
    }
  },
  components: {
    ListGroupItemComponent,
    [DatePicker.name]: DatePicker
  },
  computed: {
    ...mapGetters({
      settings: 'common/settings'
    }),

    pickerOptions() {
      return {firstDayOfWeek: 1};
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.paymentForm.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              resolve(this.deadlineDay);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
}
</script>

<style scoped>

</style>
