<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.status')">
      <template slot="value">
        <div v-if="dealStatuses.length > 0">
          <base-input class="mb-0 flex-grow-1" >
            <el-select
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
              v-model="dealLocal.status"
              @change="() => dealLocal.sub_status = null"
            >
              <el-option
                v-for="option in dealStatuses"
                class="select-default text-uppercase w-100"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div v-else>
          <badge
            :style="{backgroundColor: schedulerService.getDealStatusColor(deal.status)}"
            class="text-uppercase"
          >
            {{ deal.status ? $t('deal.status.' + deal.status) : '' }}
          </badge>
        </div>

      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.substatus')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1">
          <el-select
            :disabled="dealSubStatuses.length === 0"
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="dealLocal.sub_status"
          >
            <el-option
              v-for="option in dealSubStatuses"
              class="select-default text-uppercase w-100"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.date_start')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-date-picker
            type="date"
            v-model="dealLocal.date_in"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            :placeholder="$t('common.select_day')"
            :picker-options="pickerOptions"
          />
        </base-input>
        <label-theme-component v-else>
          {{ dealLocal.date_in | date }}
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.date_out')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-date-picker
            type="date"
            v-model="dealLocal.date_out"
            value-format="yyyy-MM-dd"
            format="dd/MM/yyyy"
            :placeholder="$t('common.select_day')"
            :picker-options="pickerOptions"
          />
        </base-input>
        <label-theme-component v-else>
          {{ dealLocal.date_out | date }}
        </label-theme-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.weekly_frequency')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" v-if="isUserManagerOrAccountant">
          <el-select
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="dealLocal.weekly_frequency"
          >
            <el-option
              v-for="(option, index) in weeklyFrequencyOptions"
              v-bind:key="`weekly-frequency-${index}`"
              class="select-default text-uppercase"
              :value="option"
              :label="option"
            />
          </el-select>
        </base-input>
        <label-theme-component v-else>
          {{ dealLocal.weekly_frequency }}
        </label-theme-component>
      </template>
    </list-group-item-component>

    <list-group-item-component :label-width="labelWidth" :label="$t('fields.classroom_info')">
      <template slot="value">
        <base-input v-model="dealLocal.classroom_info" :placeholder="$t('common.add_classroom_info')" class="flex-grow-1"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label-width="labelWidth" :label="$t('fields.individual_course')">
      <template slot="value">
        <el-switch v-model="dealLocal.individual_course" v-if="isUserManagerOrAccountant"/>
        <label-theme-component v-else>
          {{ dealLocal.individual_course ? $t('common.yes') : $t('common.no') }}
        </label-theme-component>
      </template>
    </list-group-item-component>

    <list-group-item-component
      v-if="isUserManagerOrAccountant"
      :label-width="labelWidth" :label="$t('fields.sponsor')">
      <template slot="value">
        <base-input class="mb-0 flex-grow-1" >
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="dealLocal.sponsor_id"
          >
            <el-option
              v-for="user in users"
              class="select-default text-uppercase"
              :value="user.id"
              :key="user.id"
              :label="`${$options.filters.optional(user, 'lastname')} ` +
                      `${$options.filters.optional(user, 'firstname')}`"
            >
            </el-option>
          </el-select>
        </base-input>
      </template>
    </list-group-item-component>

    <list-group-item-component :label-width="labelWidth" :label="$t('fields.notes')">
      <template slot="value">
        <base-text-area v-model="dealLocal.text" class="flex-grow-1"/>
      </template>
    </list-group-item-component>
  </ul>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Deal from "@/models/deal";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import {DatePicker, Select, Option, Switch} from "element-ui";
import DealAccountingStatus from "../resources/dealAccountingStatus";
import DealStatuses from "../resources/dealStatuses";
import Badge from "@/components/Badge";
import {schedulerService} from "@/util/scheduler-service";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {permissionService} from "@/util/permission-service";
import dealWeeklyFrequencies from "@/pages/Deals/resources/dealWeeklyFrequencies";
import {mapGetters} from "vuex";

export default {
  name: "DealCardForm",
  components: {
    LabelThemeComponent,
    Badge,
    BaseTextArea,
    ListGroupItemComponent,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  data() {
    return {
      labelWidth: 150,
      dealLocal: this.$_.cloneDeep(Deal),
      dealAccountingStatus: DealAccountingStatus,
      schedulerService: schedulerService,
      weeklyFrequencyOptions: dealWeeklyFrequencies,
    }
  },
  props: {
    deal: {
      type: Object,
      default: () => _.cloneDeep(Deal)
    },
    availableTransitions: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.dealLocal = this.$_.cloneDeep(this.deal);
  },
  methods: {
    getDealData() {
      return {
        status: this.dealLocal.status,
        sub_status: this.dealLocal.sub_status,
        text: this.dealLocal.text,
        date_out: this.dealLocal.date_out,
        weekly_frequency: this.dealLocal.weekly_frequency,
        classroom_info: this.dealLocal.classroom_info,
        individual_course: !!this.dealLocal.individual_course,
        sponsor_id: this.dealLocal.sponsor_id,
      };
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
    }),
    dealStatuses() {
      return this.$_.map(this.$_.filter((DealStatuses), (itemToFilter) => {
        return this.availableTransitions.includes(itemToFilter.value);
      }), (itemToMap) => {
        return {
          value: itemToMap.value,
          label: this.$t('deal.status.' + itemToMap.value)
        };
      })
    },

    dealSubStatuses() {
      if (!this.dealLocal.status) {
        return [];
      }

      return this.$_.map(DealStatuses[this.dealLocal.status].subStatuses, (subStatus) => {
        return {
          value: subStatus,
          label: this.$t('deal.status.' + subStatus)
        };
      })
    },

    isUserManagerOrAccountant() {
      return permissionService.isAllowed([
        permissionService.CALL_CENTER_MANAGER_ROLE,
        permissionService.SALES_MANAGER_ROLE,
        permissionService.ACCOUNTANT_ROLE,
      ]);
    },
    pickerOptions() {
      return {firstDayOfWeek: 1};
    }
  }
}
</script>

<style scoped lang="scss">

</style>
