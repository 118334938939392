const PaymentReasonConst = {
  enrollment_fee: {
    value: 'enrollment_fee'
  },
  exam_fee: {
    value: 'exam_fee'
  },
  single_payment: {
    value: 'single_payment'
  },
  excerpt_closure: {
    value: 'excerpt_closure'
  },
  pc_rental: {
    value: 'pc_rental'
  },
  refund: {
    value: 'refund'
  },
  credit_recovery: {
    value: 'credit_recovery',
    color: 'danger',
  },
  career_advisor: {
    value: 'career_advisor'
  },
  installment_one: {
    value: 'installment_one'
  },
  installment_two: {
    value: 'installment_two'
  },
  installment_three: {
    value: 'installment_three'
  },
  installment_four: {
    value: 'installment_four'
  },
  installment_five: {
    value: 'installment_five'
  },
  installment_six: {
    value: 'installment_six'
  },
  installment_seven: {
    value: 'installment_seven'
  },
  installment_eight: {
    value: 'installment_eight'
  },
  installment_nine: {
    value: 'installment_nine'
  },
  installment_ten: {
    value: 'installment_ten'
  },
  installment_eleven: {
    value: 'installment_eleven'
  },
  installment_twelve: {
    value: 'installment_twelve'
  },
  installment_thirteen: {
    value: 'installment_thirteen'
  },
  installment_fourteen: {
    value: 'installment_fourteen'
  },
  installment_fifteen: {
    value: 'installment_fifteen'
  },
  installment_sixteen: {
    value: 'installment_sixteen'
  },
  installment_seventeen: {
    value: 'installment_seventeen'
  },
  installment_eighteen: {
    value: 'installment_eighteen'
  },
  installment_nineteen: {
    value: 'installment_nineteen'
  },
  installment_twenty: {
    value: 'installment_twenty'
  },
  installment_twenty_one: {
    value: 'installment_twenty_one'
  },
  installment_twenty_two: {
    value: 'installment_twenty_two'
  },
  installment_twenty_three: {
    value: 'installment_twenty_three'
  },
  installment_twenty_four: {
    value: 'installment_twenty_four'
  },
  installment_twenty_five: {
    value: 'installment_twenty_five'
  },
  installment_twenty_six: {
    value: 'installment_twenty_six'
  },
  installment_twenty_seven: {
    value: 'installment_twenty_seven'
  },
  installment_twenty_eight: {
    value: 'installment_twenty_eight'
  },
  installment_twenty_nine: {
    value: 'installment_twenty_nine'
  },
  installment_thirty: {
    value: 'installment_thirty'
  },
}

export default PaymentReasonConst;
